import axios from 'axios';
import {Message, Modal} from '@arco-design/web-vue';
import router from "@/router";

// axios.defaults.baseURL = "http://115.227.0.154:3333"
// axios.defaults.baseURL = "http://127.0.0.1:3333"

axios.interceptors.request.use((config) => {
    if (!config.headers) {
        config.headers = {};
    }
    config.headers.Authorization = `${localStorage.getItem('token')}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});


axios.interceptors.response.use((response) => {
    const res = response.data;

    if (res.code !== 200) {
        Message.error({
            content: res.message || 'Error',
            duration: 5 * 1000,
        });
        if (res.code === 401) {
            Modal.error({
                title: '提醒',
                content: '登录过期，需要重新登录',
                okText: '重新登录',
                async onOk() {
                    // const userStore = useUserStore();

                    // await userStore.logout();
                    localStorage.removeItem('token')
                    router.push('/login')
                    // window.location.reload();
                },
            });
        }

        return Promise.reject(new Error(res.message || 'Error'));
    }
    return res;
}, (error) => {
    Message.error({
        content: error.message || 'Request Error',
        duration: 5 * 1000,
    });
    return Promise.reject(error);
});
